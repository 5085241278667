/* eslint-disable no-nested-ternary */

/* eslint-disable react/display-name */

/* eslint-disable react/no-unstable-nested-components */

/* eslint-disable react/no-unused-class-component-methods */
import { motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import taooCoin from 'assets/Images/taoo-coin.png';
import DateMonthYearPicker from 'components/form/date-month-year-picker';
import Input from 'components/form/input/index';
import Img from 'components/img/Img';
import { switchMessageStep } from 'constants/index';

import Error from './error';
import styles from './index.module.scss';

const Step = React.forwardRef(({
    error, showMessage, remeberedStep, familyname = '',
}, ref) => (
    <motion.div
        variants={switchMessageStep}
        initial="initial"
        animate="animate"
        exit="exit"
        className="d-flex flex-column pos-relative flex-grow-1"
    >
        {showMessage ? (
            remeberedStep ? (
                <>
                    <h1 className="mt-3">
                        <FormattedMessage id="steps.welcomeback" />
                    </h1>
                    <p>
                        <FormattedMessage id="steps.you_have_atteined_2/3" />
                    </p>
                </>
            ) : (
                <>
                    <h1 className="mt-3">
                        <FormattedMessage id="onboarding.step1.msg.encouragement" />{' '}
                        <span className="svg-wrapper d-inline-flex">
                            <svg
                                className="pos-relative"
                                style={{ top: '5px' }}
                                width="37"
                                height="39"
                                viewBox="0 0 37 39"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g filter="url(#filter0_d_254_453)">
                                    <path
                                        d="M17.6011 30.5746C11.439 30.5746 4.78516 26.7099 4.78516 18.228C4.78516 9.74612 11.439 5.88145 17.6011 5.88145C21.0237 5.88145 24.183 7.00658 26.5251 9.06061C29.0635 11.3158 30.4121 14.495 30.4121 18.228C30.4121 21.961 29.0635 25.1203 26.5251 27.3731C24.183 29.4271 21.0013 30.5746 17.6011 30.5746Z"
                                        fill="url(#paint0_radial_254_453)"
                                    />
                                    <path
                                        d="M28.179 10.8886C29.3911 12.9004 30.012 15.2152 29.9698 17.5648C29.9698 21.2904 28.6211 24.4572 26.0828 26.7099C23.7406 28.7639 20.5615 29.9114 17.1588 29.9114C13.1699 29.9114 8.97992 28.2895 6.51855 24.8446C8.89547 28.7465 13.3637 30.5745 17.6009 30.5745C21.0011 30.5745 24.1827 29.4271 26.5249 27.373C29.0632 25.1178 30.4119 21.961 30.4119 18.228C30.4119 15.4139 29.6444 12.9128 28.179 10.8886Z"
                                        fill="#EB8F00"
                                    />
                                    <path
                                        opacity="0.8"
                                        d="M10.1995 21.4593C12.6 21.4593 14.546 19.6245 14.546 17.3612C14.546 15.0978 12.6 13.263 10.1995 13.263C7.79902 13.263 5.85303 15.0978 5.85303 17.3612C5.85303 19.6245 7.79902 21.4593 10.1995 21.4593Z"
                                        fill="url(#paint1_radial_254_453)"
                                    />
                                    <path
                                        opacity="0.8"
                                        d="M25.2015 21.4593C27.602 21.4593 29.548 19.6245 29.548 17.3612C29.548 15.0978 27.602 13.263 25.2015 13.263C22.801 13.263 20.855 15.0978 20.855 17.3612C20.855 19.6245 22.801 21.4593 25.2015 21.4593Z"
                                        fill="url(#paint2_radial_254_453)"
                                    />
                                    <path
                                        d="M7.76595 4.54521C8.72615 4.54521 9.50455 3.98921 9.50455 3.30335C9.50455 2.61749 8.72615 2.06149 7.76595 2.06149C6.80574 2.06149 6.02734 2.61749 6.02734 3.30335C6.02734 3.98921 6.80574 4.54521 7.76595 4.54521Z"
                                        fill="#FF00A2"
                                    />
                                    <path
                                        d="M15.0791 6.84767C15.4865 6.44029 15.3843 5.67759 14.8508 5.14412C14.3174 4.61065 13.5547 4.50843 13.1473 4.91581C12.7399 5.32318 12.8421 6.08589 13.3756 6.61936C13.9091 7.15283 14.6718 7.25504 15.0791 6.84767Z"
                                        fill="#FD3B3B"
                                    />
                                    <path
                                        d="M20.407 4.86707C21.3182 4.16178 21.7914 3.24708 21.4639 2.82402C21.1365 2.40097 20.1323 2.62976 19.2212 3.33505C18.31 4.04034 17.8367 4.95504 18.1642 5.3781C18.4917 5.80115 19.4958 5.57236 20.407 4.86707Z"
                                        fill="#00EEFF"
                                    />
                                    <path
                                        d="M8.25136 21.2621C8.59585 21.0632 8.51372 20.276 8.06791 19.5039C7.6221 18.7317 6.98144 18.267 6.63694 18.4659C6.29245 18.6648 6.37458 19.452 6.82039 20.2241C7.2662 20.9963 7.90687 21.461 8.25136 21.2621Z"
                                        fill="#0048FF"
                                    />
                                    <path
                                        d="M25.7721 28.3889C26.2522 28.3889 26.6414 27.7773 26.6414 27.0228C26.6414 26.2684 26.2522 25.6568 25.7721 25.6568C25.292 25.6568 24.9028 26.2684 24.9028 27.0228C24.9028 27.7773 25.292 28.3889 25.7721 28.3889Z"
                                        fill="#F63BBE"
                                    />
                                    <path
                                        d="M30.7474 14.1646C31.4765 13.6514 31.9395 13.0535 31.7816 12.8292C31.6237 12.6048 30.9047 12.839 30.1756 13.3522C29.4465 13.8654 28.9835 14.4633 29.1414 14.6877C29.2993 14.912 30.0184 14.6778 30.7474 14.1646Z"
                                        fill="#00CC00"
                                    />
                                    <path
                                        d="M7.38813 29.6397C7.67485 29.4516 7.54126 28.7412 7.08974 28.0531C6.63823 27.3649 6.03977 26.9596 5.75305 27.1477C5.46632 27.3359 5.59991 28.0462 6.05143 28.7344C6.50294 29.4225 7.1014 29.8278 7.38813 29.6397Z"
                                        fill="#0048FF"
                                    />
                                    <path
                                        d="M5.57443 24.7862C6.3954 24.7279 7.04125 24.4034 7.01697 24.0613C6.99269 23.7193 6.30748 23.4892 5.4865 23.5475C4.66553 23.6057 4.01968 23.9303 4.04396 24.2723C4.06824 24.6144 4.75345 24.8445 5.57443 24.7862Z"
                                        fill="#7ACDED"
                                    />
                                    <path
                                        d="M7.79598 21.5334C8.78145 21.4678 9.56777 21.226 9.55229 20.9933C9.5368 20.7606 8.72537 20.6252 7.73991 20.6908C6.75444 20.7563 5.96812 20.9981 5.9836 21.2308C5.99909 21.4635 6.81052 21.5989 7.79598 21.5334Z"
                                        fill="#FF82B2"
                                    />
                                    <path
                                        d="M31.3361 19.0004C31.125 18.2702 30.6208 17.6592 29.9452 17.3115C28.8027 16.6906 27.3721 17.1153 26.7536 18.2578C26.7487 18.2652 26.7462 18.2727 26.7412 18.2801C26.2246 19.2538 26.5897 20.4633 27.5608 20.9874C27.9458 21.2035 28.3979 21.2656 28.8275 21.1613C29.2696 21.0619 29.6422 20.7713 29.8459 20.3665C30.0669 19.8995 30.047 19.3531 29.7962 18.9011C29.5925 18.5037 29.1976 18.2404 28.753 18.2056C28.4972 18.2007 28.2886 18.4043 28.2836 18.6602C28.2811 18.8911 28.45 19.0898 28.6785 19.1246C28.8052 19.1321 28.9194 19.2091 28.9766 19.3233C29.0635 19.4997 29.0809 19.7058 29.0262 19.8946C28.9418 20.061 28.7878 20.1802 28.604 20.2174C28.4028 20.2696 28.1892 20.2423 28.0079 20.1429C27.7595 20.0113 27.5733 19.7878 27.4863 19.522C27.4118 19.2488 27.4391 18.9582 27.5608 18.7024C27.7347 18.3646 28.0402 18.1113 28.4053 18.0069C28.7704 17.9026 29.1579 17.9374 29.4981 18.1063C29.9427 18.3497 30.2731 18.762 30.4171 19.2488C30.5686 19.7381 30.514 20.2671 30.2681 20.7142C29.7962 21.6083 28.8772 22.2044 27.536 22.2044C26.766 22.2044 22.1836 22.0802 22.1836 22.0802L22.2382 23.2227L27.4863 23.1482C29.0511 23.1482 30.3426 22.5273 31.0877 21.1364C31.4801 20.4956 31.5696 19.7132 31.3361 19.0004Z"
                                        fill="url(#paint3_linear_254_453)"
                                    />
                                    <path
                                        d="M19.1907 22.6763C19.3422 22.8378 19.4589 23.0265 19.5384 23.2327L22.3823 23.2153C22.3823 22.8427 22.3326 22.4702 22.3326 22.0728C21.5875 22.0479 20.0525 22.0231 19.5757 22.0057C19.5061 22.2615 19.3695 22.495 19.1758 22.6763H19.1907Z"
                                        fill="#7F0099"
                                    />
                                    <path
                                        d="M27.8343 13.1885L28.7036 13.3127L28.9272 12.4434L29.0514 11.9467L24.0591 11.2512C26.3193 12.965 27.6853 13.1885 27.8343 13.1885Z"
                                        fill="#FF4545"
                                    />
                                    <path
                                        d="M31.8328 0.918976L29.821 2.06149L29.3242 2.33469L31.4105 2.63274C31.5596 2.01181 31.8328 0.918976 31.8328 0.918976Z"
                                        fill="#FF4545"
                                    />
                                    <path
                                        d="M27.3867 3.42752L25.3252 4.59487L30.7149 5.36482L31.0626 3.9491L27.3867 3.42752Z"
                                        fill="#FF4545"
                                    />
                                    <path
                                        d="M21.5004 6.75571H21.4756L30.0444 7.99757L30.3673 6.6812L23.388 5.68771L21.5004 6.75571Z"
                                        fill="#FF4545"
                                    />
                                    <path
                                        d="M22.2207 9.612L29.3738 10.6303L29.7215 9.28911L21.103 8.04726C21.2024 8.32047 21.5004 8.86688 22.2207 9.612Z"
                                        fill="#FF4545"
                                    />
                                    <path
                                        d="M23.3887 5.6877L30.3679 6.68119L30.7156 5.36482L25.326 4.59486L23.3887 5.6877Z"
                                        fill="#FF9C9C"
                                    />
                                    <path
                                        d="M31.2617 3.17917C31.2617 3.17917 31.3363 2.83145 31.3859 2.65759L29.2996 2.35954L27.3623 3.45238L31.0382 3.97396L31.2617 3.17917Z"
                                        fill="#FF9C9C"
                                    />
                                    <path
                                        d="M20.7559 7.15312L21.0291 7.94791C21.039 7.99262 21.0564 8.03484 21.0787 8.0721L29.6972 9.31395L30.0201 7.99758L21.4513 6.78056L20.7559 7.15312Z"
                                        fill="#FF9C9C"
                                    />
                                    <path
                                        d="M23.9593 11.1767C23.9841 11.2016 24.0338 11.2264 24.0587 11.2512L29.0509 11.9715L29.3738 10.6551L22.2207 9.63683C22.7646 10.1882 23.3458 10.7023 23.9593 11.1767Z"
                                        fill="#FF9C9C"
                                    />
                                    <path
                                        d="M31.8079 2.38437C32.4663 2.38437 33.0001 1.85061 33.0001 1.19218C33.0001 0.533759 32.4663 0 31.8079 0C31.1495 0 30.6157 0.533759 30.6157 1.19218C30.6157 1.85061 31.1495 2.38437 31.8079 2.38437Z"
                                        fill="#C93737"
                                    />
                                    <path
                                        d="M19.6475 22.3857C20.0126 22.0703 20.2411 21.6257 20.2858 21.1439C20.3553 20.6596 20.2113 20.1703 19.8884 19.8027C19.3097 19.0427 17.7002 19.2314 17.7002 19.2314C17.2681 19.2488 16.7689 19.455 16.8309 19.9517C16.9129 20.2373 17.1439 20.4559 17.432 20.5205C18.0678 20.672 18.9744 20.5205 18.9992 21.1662C19.029 21.8915 17.9163 21.5934 17.8269 22.3609C17.7375 23.1284 18.9992 23.0265 18.9992 23.5556C18.9992 23.9604 18.5845 24.03 18.1473 24.1219C17.7723 24.1989 17.052 24.3156 16.9949 24.7453C16.9377 25.175 17.1836 25.704 18.197 25.55C19.7915 25.3016 20.328 24.4348 20.328 23.8983C20.338 23.3171 20.0896 22.7633 19.6475 22.3857Z"
                                        fill="#422B0D"
                                    />
                                    <path
                                        d="M14.1891 15.8287L14.1519 15.7815L14.0525 15.6548L13.9259 15.5182C13.8762 15.4636 13.8166 15.404 13.757 15.3444C13.6949 15.2848 13.6303 15.2276 13.5608 15.1755C13.4987 15.1283 13.4341 15.0861 13.3645 15.0488C13.3174 15.0215 13.2652 15.0016 13.2106 14.9917C13.1932 14.9892 13.1783 14.9892 13.1609 14.9917C13.1609 14.9917 13.1609 14.9917 13.141 14.9917H13.1857H13.0566C13.0243 14.9917 13.0566 14.9917 13.0566 14.9917H13.074C13.064 14.9892 13.0566 14.9892 13.0466 14.9917C12.992 15.0016 12.9398 15.0215 12.8926 15.0488C12.8231 15.0861 12.7585 15.1283 12.6964 15.1755C12.6319 15.2252 12.5648 15.2848 12.5002 15.3444C12.3785 15.4636 12.2717 15.5928 12.1972 15.6697L12.078 15.8113L12.0233 15.8734C11.6856 16.2485 11.1193 16.3081 10.7095 16.015C10.4487 15.8337 10.3145 15.5182 10.3642 15.2028C10.3642 15.2028 10.3816 15.1084 10.4263 14.9544C10.4959 14.716 10.5952 14.4875 10.7194 14.2739C10.9131 13.9212 11.1764 13.6107 11.4918 13.3624C11.7079 13.1985 11.9463 13.0668 12.2022 12.9749C12.2717 12.9476 12.3462 12.9252 12.4183 12.9079C12.5002 12.883 12.5822 12.8656 12.6666 12.8532L12.8107 12.8333H12.9299H13.0591H13.2428H13.3621C13.4391 12.8333 13.5185 12.8532 13.5955 12.8656C13.747 12.893 13.8936 12.9327 14.0376 12.9849C14.2935 13.0767 14.5319 13.2084 14.748 13.3723C15.0684 13.6157 15.3391 13.9212 15.5378 14.2714C15.6098 14.3931 15.6744 14.5223 15.7266 14.6539C15.7738 14.7632 15.8085 14.8799 15.8383 14.9669C15.8681 15.0538 15.8657 15.096 15.8756 15.1382V15.1954C15.9426 15.6747 15.6073 16.1193 15.128 16.1864C15.1181 16.1888 15.1081 16.1888 15.1007 16.1888C14.7554 16.241 14.4052 16.1044 14.1891 15.8287Z"
                                        fill="#422B0D"
                                    />
                                    <path
                                        d="M23.4131 15.8287L23.3758 15.7815L23.2765 15.6548L23.1647 15.5182C23.115 15.4636 23.0554 15.404 22.9958 15.3444C22.9337 15.2848 22.8692 15.2276 22.7996 15.1755C22.7375 15.1283 22.6729 15.0861 22.6034 15.0488C22.5562 15.0215 22.5041 15.0016 22.4519 14.9917C22.4345 14.9892 22.4196 14.9892 22.4022 14.9917C22.4022 14.9917 22.3873 14.9917 22.3824 14.9917H22.4196H22.2905H22.3078H22.2805C22.2284 15.0016 22.1762 15.0215 22.129 15.0488C22.0595 15.0861 21.9949 15.1283 21.9328 15.1755C21.8633 15.2276 21.7987 15.2848 21.7366 15.3444C21.6124 15.4636 21.5081 15.5928 21.4311 15.6697L21.3119 15.8113L21.2572 15.8734C20.9194 16.2485 20.3532 16.3081 19.9433 16.015C19.6826 15.8337 19.5484 15.5182 19.5981 15.2028C19.5981 15.2028 19.6155 15.1084 19.6627 14.9544C19.7248 14.7135 19.8167 14.48 19.9359 14.2615C20.1346 13.9113 20.4053 13.6058 20.7257 13.3624C20.9418 13.1985 21.1827 13.0668 21.4385 12.9749C21.5081 12.9476 21.5801 12.9252 21.6521 12.9079C21.7341 12.883 21.8161 12.8656 21.9005 12.8532L22.0446 12.8333H22.1638H22.2929H22.4767H22.596C22.6754 12.8333 22.7524 12.8532 22.8294 12.8656C23.2492 12.9377 23.6441 13.109 23.9843 13.3624C24.3047 13.6058 24.573 13.9113 24.7742 14.2615C24.8462 14.3832 24.9083 14.5123 24.9605 14.644C25.0052 14.7458 25.0424 14.8501 25.0722 14.9569C25.0896 15.014 25.102 15.0712 25.1095 15.1283V15.1854C25.179 15.6623 24.8462 16.1069 24.3693 16.1739C24.3594 16.1764 24.3495 16.1764 24.337 16.1789C23.9893 16.2336 23.6366 16.1019 23.4131 15.8287Z"
                                        fill="#422B0D"
                                    />
                                </g>
                                <defs>
                                    <filter
                                        id="filter0_d_254_453"
                                        x="0.043457"
                                        y="0"
                                        width="36.9565"
                                        height="38.5746"
                                        filterUnits="userSpaceOnUse"
                                        colorInterpolationFilters="sRGB"
                                    >
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix
                                            in="SourceAlpha"
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"
                                        />
                                        <feOffset dy="4" />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                        />
                                        <feBlend
                                            mode="normal"
                                            in2="BackgroundImageFix"
                                            result="effect1_dropShadow_254_453"
                                        />
                                        <feBlend
                                            mode="normal"
                                            in="SourceGraphic"
                                            in2="effect1_dropShadow_254_453"
                                            result="shape"
                                        />
                                    </filter>
                                    <radialGradient
                                        id="paint0_radial_254_453"
                                        cx="0"
                                        cy="0"
                                        r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(17.5986 18.228) scale(12.5822 12.5822)"
                                    >
                                        <stop offset="0.5" stopColor="#FDE030" />
                                        <stop offset="0.92" stopColor="#F7C02B" />
                                        <stop offset="1" stopColor="#F4A223" />
                                    </radialGradient>
                                    <radialGradient
                                        id="paint1_radial_254_453"
                                        cx="0"
                                        cy="0"
                                        r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(10.1995 18.2352) scale(4.73093 4.24818)"
                                    >
                                        <stop stopColor="#ED7770" />
                                        <stop offset="0.9" stopColor="#ED7770" stopOpacity="0" />
                                    </radialGradient>
                                    <radialGradient
                                        id="paint2_radial_254_453"
                                        cx="0"
                                        cy="0"
                                        r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(33.2289 290.816) scale(4.82586)"
                                    >
                                        <stop stopColor="#ED7770" />
                                        <stop offset="0.9" stopColor="#ED7770" stopOpacity="0" />
                                    </radialGradient>
                                    <linearGradient
                                        id="paint3_linear_254_453"
                                        x1="22.2184"
                                        y1="20.1244"
                                        x2="31.4553"
                                        y2="20.1244"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor="#004054" />
                                        <stop offset="0.99" stopColor="#45A4FF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </span>
                    </h1>
                    <h3>
                        <FormattedMessage id="onboarding.step2.msg.encouragement.msg" />{' '}
                        <span className={styles.taao_point} />
                        <span className={styles.point_image}>
                            <Img src={taooCoin} alt="taoo logo" />
                        </span>
                    </h3>
                    <p>
                        <FormattedMessage id="onboarding.levelup.step2.paragraph" />
                    </p>
                </>
            )
        ) : (
            <>
                <h3 className="mt-3">
                    <FormattedMessage id="onboarding.step2.easy_30" />
                </h3>
                <p>
                    <FormattedMessage id="onboarding.step2.more_info" />
                </p>
                <FormattedMessage id="onboarding.step2.input1_placeholder">
                    {(msg) => (
                        <Input ref={ref} name="familyname" type="text" placeholder={msg} defaultValue={familyname} />
                    )}
                </FormattedMessage>
                <FormattedMessage id="onboarding.step2.input3_placeholder">
                    {(msg) => (
                        <>
                            <p
                                style={{
                                    fontSize:     '1rem',
                                    color:        '#989898',
                                    marginBottom: '0.5rem',
                                }}
                            >
                                {msg}
                            </p>
                            {/* <DateMonthYearPicker /> */}
                        </>
                    )}
                </FormattedMessage>
                <Error error={error} />
                <p className="mt-auto mb-5">
                    <FormattedMessage id="onboarding.step1.promise" />{' '}
                    <span className="svg-wrapper d-inline-flex">
                        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.1663 6.24589C3.06938 7.27974 3.00137 9.10937 3.61181 9.88815C3.61181 9.88815 3.32444 7.87828 5.90055 5.35658C6.9378 4.34144 7.17755 2.96071 6.81537 1.92517C6.60962 1.33853 6.23383 0.853917 5.90735 0.515537C5.71691 0.31659 5.86314 -0.0115878 6.14031 0.000315011C7.8169 0.0751327 10.5341 0.541042 11.6887 3.43853C12.1954 4.71043 12.2328 6.02484 11.9914 7.36136C11.8384 8.21496 11.2942 10.1126 12.5355 10.3456C13.4214 10.5122 13.8499 9.80823 14.0421 9.30151C14.122 9.09066 14.3992 9.03795 14.5488 9.20629C16.0451 10.9084 16.1727 12.9132 15.8632 14.6391C15.2647 17.9753 11.886 20.4034 8.52937 20.4034C4.33618 20.4034 0.998292 18.0042 0.132787 13.6613C-0.215795 11.9082 -0.0389532 8.43941 2.66469 5.99083C2.86533 5.80719 3.19351 5.97043 3.1663 6.24589Z"
                                fill="url(#paint0_radial_291_97)"
                            />
                            <path
                                d="M10.0615 12.4847C8.51579 10.4952 9.20785 8.22516 9.58704 7.32054C9.63805 7.20152 9.50202 7.08929 9.39489 7.16241C8.73004 7.61471 7.36801 8.67917 6.73376 10.1772C5.87506 12.2024 5.93628 13.1937 6.4447 14.4044C6.75077 15.1339 6.39538 15.2886 6.21684 15.3158C6.0434 15.343 5.88356 15.2274 5.75603 15.1067C5.39045 14.7581 5.12688 14.3041 5.00105 13.8144C4.97385 13.709 4.83612 13.68 4.7732 13.7668C4.29709 14.4248 4.05053 15.4808 4.03863 16.2273C4.00122 18.5347 5.90737 20.4051 8.21311 20.4051C11.1191 20.4051 13.2361 17.1914 11.5663 14.5047C11.0817 13.7226 10.626 13.2107 10.0615 12.4847Z"
                                fill="url(#paint1_radial_291_97)"
                            />
                            <defs>
                                <radialGradient
                                    id="paint0_radial_291_97"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(7.69891 20.4563) rotate(-179.751) scale(12.0027 19.6941)"
                                >
                                    <stop offset="0.3144" stopColor="#FF9800" />
                                    <stop offset="0.6616" stopColor="#FF6D00" />
                                    <stop offset="0.9715" stopColor="#F44336" />
                                </radialGradient>
                                <radialGradient
                                    id="paint1_radial_291_97"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(8.37292 8.51234) rotate(90.5787) scale(12.5585 9.45124)"
                                >
                                    <stop offset="0.2141" stopColor="#FFF176" />
                                    <stop offset="0.3275" stopColor="#FFF27D" />
                                    <stop offset="0.4868" stopColor="#FFF48F" />
                                    <stop offset="0.6722" stopColor="#FFF7AD" />
                                    <stop offset="0.7931" stopColor="#FFF9C4" />
                                    <stop offset="0.8221" stopColor="#FFF8BD" stopOpacity="0.804" />
                                    <stop offset="0.8627" stopColor="#FFF6AB" stopOpacity="0.529" />
                                    <stop offset="0.9101" stopColor="#FFF38D" stopOpacity="0.2088" />
                                    <stop offset="0.9409" stopColor="#FFF176" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </span>
                </p>
            </>
        )}
    </motion.div>
));

export default Step;
